<template>
    <div>
        <wit-input-group
            v-model="$v.localForm.accessToken.$model"
            :step="step"
            :disabled="disabled"
            :model="$v.localForm.accessToken"
            type="password"
            inputLabel="Access Token"
            placeholder="Enter your access token"
            invalid="This field is required"
            valid="Token is valid"
            @input="emitInput"
        ></wit-input-group>
        <wit-input-group
            v-model="$v.localForm.domain.$model"
            :step="step"
            :disabled="disabled"
            :model="$v.localForm.domain"
            type="text"
            inputLabel="Domain"
            placeholder="Enter your site domain"
            invalid="This field is required and must be a valid domain"
            valid="Domain is valid"
            @input="emitInput"
        ></wit-input-group>
    </div>
</template>

<script>
import {required} from 'vuelidate/lib/validators'
import {tokenFormMixin} from '@/mixins/tokenFormMixin'

export default {
    mixins: [tokenFormMixin],
    data() {
        return {
            localForm: {
                accessToken: '',
                domain: '',
            },
        }
    },
    validations: {
        localForm: {
            accessToken: {
                required,
            },
            domain: {
                required,
                valid: value => /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/.test(value),
            },
        },
    },
    methods: {
        init() {
            this.localForm = {
                accessToken: '',
                domain: '',
                ...this.value,
            }
        },
    },
}
</script>

<style></style>
