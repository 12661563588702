import {validationMixin} from 'vuelidate'

export const tokenFormMixin = {
    components: {
        WitInputGroup: () => import('@/components/Inputs/WitInputGroup.vue'),
    },
    mixins: [validationMixin],
    props: {
        value: {
            type: Object,
            required: true,
        },
        step: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        valid: {
            type: Boolean,
        },
        formId: {
            type: String,
        },
    },
    data() {
        return {
            localForm: {},
        }
    },
    mounted() {
        this.init()
        this.emitInput()
    },
    methods: {
        emitInput() {
            this.emitValid()
            this.$emit('input', this.localForm)
        },

        emitValid() {
            this.$emit('update:valid', !this.$v.localForm.$invalid)
        },

        init() {},
    },
}
